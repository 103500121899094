.App-home{
    background-color: #282c342d;
    height: auto;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2.5px + 3.5vmin);
    color: white;
    min-height: 100vh;
}
.fullSize{
    height: 100vh;
    width: 100vw;
    background: center no-repeat;
    background-size: cover;
    padding: 0%;
}
.bannerSection{
    height: 90vh;
    width: 90vw;
    padding: 5vh 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Banner images*/
#Banner{
    background-image: url("../images/BackGround/Foto_Home_1366x768.png") ;  
    justify-content: left;
}
#Home0{
    background-image: url("../images/BackGround/Foto_ObraCivil_1366x768.png") ;  
}
#Home1{
    background-image: url("../images/BackGround/Foto_PinturaAcabados_1366x768.png") ;  
}
#Home2{
    background-image: url("../images/BackGround/Foto_CableadoEstructurado_1366x768.png") ;  
}
#Home3{
    background-image: url("../images/BackGround/Foto_SistemaSeguridad_1366x768.png") ;  
}
#Home4{
    background-image: url("../images/BackGround/Foto_RedesELectricas_1366x768.png") ;  
}
#Home5{
    background-image: url("../images/BackGround/Foto_SistemaLiviano_1366x768.png") ;  
}


#BannerInfo{
    max-width: 50vw;
    margin-top: 70vh;
}
ul{
    list-style: none;
    text-align: center;
    padding: 0%;
    margin: 0%;
}
li{
    vertical-align: middle
}
.btnObj{
    float: none;
    width: 36vw;
    height: 14vw;
    display: block;
    margin: 10%;
    margin: 5% auto ;
    /*font-size: calc(3.5px + 4vmin);*/
    font-size: 80%;
    border: solid 0.2vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    -webkit-transition: 1s; /* Safari */
    transition:  0.5s;
    box-shadow: 0vh ;
}

.btnObj:hover{
    width: 38vw;
    height: 40vw;
    box-shadow: 0.8vh 0.8vh  #686868a8;
    border: solid 0.4vh;
}

/*Blue section*/

.blueSection h1{
    color: #00aeff;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.blueSection p{
    margin-top: 0px;
}
.yellowSection .bannerSection{
    background: #ffdb4a2f;
}
.yellowSection .infoBtn{
    background: #ffdc4a;
    Border-color: #ffdc4a;
    color: #ffffff;
}
.yellowSection .galeryBtn{
    background: none;
    Border-color: #ffdc4a;
    color: #ffdc4a;
}
.blueSection .infoBtn{
    background: #00aeff;
    color: #ffffff;
    border-color: #00aeff;
}

.blueSection .galeryBtn{
    background: none;
    Border-color: #00aeff;
    color: #00aeff;
}
.blueSection .bannerSection{
    background: #00aeff2f;
}
@media only screen and (min-device-width : 768px) {
    .btnObj{
        max-width: 180px;
        max-height: 70px;
        font-size: 60%;
    }
  }