.App-header {
  background-color: #282c342d;
  height: 8vh;
  width: 100vw;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2.5px + 1vmin);
  color: white;
  position: fixed;
  z-index: 100;
}
a,
a:visited:active {
  color: white;
  display: block;
}

.yellowSection a:active,
.yellowSection a:visited:active {
  color: #00aeff;
  display: block;
}
.blueSection a:active,
.blueSection a:visited:active {
  color: #ffdc4a;
  display: block;
}

.blueSection a:hover {
  color: #00aeff;
  display: block;
}
.yellowSection a:hover {
  color: #ffdc4a;
  display: block;
}
.Contacto_Azul {
  color: #ffdc4a;
  display: block;
}
.Contacto_Amarillo {
  color: #00aeff;
  display: block;
}
/*
a:link:active{

}*/
#Logo {
  height: 4vh;
  float: left;
  width: 10vw;
  max-height: 100%;
  margin: 2vh 2vw;
}
.App-logo {
  max-height: 100%;
  max-width: 100%;
}
nav {
  float: right;
  width: 70%;
  height: 100%;
}
nav ul {
  list-style: none;
  height: 80%;
  margin: 1% 0%;
  padding: 0% 1%;
}
nav ul li {
  float: left;
  display: block;
  width: 10%;
  margin: 1vh 1.4vw;
  padding-left: 3.9vw;
  text-align: right;
  font-size: 1.7vmin;
}
.MobileMenu {
  display: none;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 768px) {
  nav ul li {
    float: right;
  }
  #Logo {
    width: 22vw;
  }
  .MobileMenu {
    display: block;
  }
  #MobileMenu li {
    display: block;
    width: 10%;
    height: 80%;
    padding-top: 1.5vh;
    margin-right: 5%;
  }
  #NavMenu {
    position: absolute;

    -webkit-transition: 1s; /* Safari */
    transition: 1s;
    padding: 0% 2%;
    background: #000000;
    height: auto;
  }
  .desactiveMenu {
    width: 50vw;
    right: -55vw;
    display: block;
    background: #00000000;
  }
  .activeMenu {
    right: 0vw;
    width: 50vw;
    display: block;
    background: #000000;
    z-index: 1;
  }
  #NavMenu li {
    float: none;
    display: block;
    width: 90%;
    margin: 4%;
    padding: 5% 0%;
    text-align: left;
    border-top: 0.3vh solid #ffffff;
    font-size: calc(1px + 3vmin);
  }
  #NavMenu li:first-child {
    border: none;
  }
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
