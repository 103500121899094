body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: Gothic_Bold;
    src: url(/static/media/GOTHICB.bc420c1c.TTF);
}
@font-face {
    font-family: Gothic;
    src: url(/static/media/GOTHIC.cfce6abb.TTF);
}
body, html{
    width: 100%;
    font-family: Gothic_Bold;
}
a, a:link:active, a:visited:active { 
    text-decoration:none; 
    color: (internal value);
}

.App-header {
  background-color: #282c342d;
  height: 8vh;
  width: 100vw;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2.5px + 1vmin);
  color: white;
  position: fixed;
  z-index: 100;
}
a,
a:visited:active {
  color: white;
  display: block;
}

.yellowSection a:active,
.yellowSection a:visited:active {
  color: #00aeff;
  display: block;
}
.blueSection a:active,
.blueSection a:visited:active {
  color: #ffdc4a;
  display: block;
}

.blueSection a:hover {
  color: #00aeff;
  display: block;
}
.yellowSection a:hover {
  color: #ffdc4a;
  display: block;
}
.Contacto_Azul {
  color: #ffdc4a;
  display: block;
}
.Contacto_Amarillo {
  color: #00aeff;
  display: block;
}
/*
a:link:active{

}*/
#Logo {
  height: 4vh;
  float: left;
  width: 10vw;
  max-height: 100%;
  margin: 2vh 2vw;
}
.App-logo {
  max-height: 100%;
  max-width: 100%;
}
nav {
  float: right;
  width: 70%;
  height: 100%;
}
nav ul {
  list-style: none;
  height: 80%;
  margin: 1% 0%;
  padding: 0% 1%;
}
nav ul li {
  float: left;
  display: block;
  width: 10%;
  margin: 1vh 1.4vw;
  padding-left: 3.9vw;
  text-align: right;
  font-size: 1.7vmin;
}
.MobileMenu {
  display: none;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 768px) {
  nav ul li {
    float: right;
  }
  #Logo {
    width: 22vw;
  }
  .MobileMenu {
    display: block;
  }
  #MobileMenu li {
    display: block;
    width: 10%;
    height: 80%;
    padding-top: 1.5vh;
    margin-right: 5%;
  }
  #NavMenu {
    position: absolute; /* Safari */
    transition: 1s;
    padding: 0% 2%;
    background: #000000;
    height: auto;
  }
  .desactiveMenu {
    width: 50vw;
    right: -55vw;
    display: block;
    background: #00000000;
  }
  .activeMenu {
    right: 0vw;
    width: 50vw;
    display: block;
    background: #000000;
    z-index: 1;
  }
  #NavMenu li {
    float: none;
    display: block;
    width: 90%;
    margin: 4%;
    padding: 5% 0%;
    text-align: left;
    border-top: 0.3vh solid #ffffff;
    font-size: calc(1px + 3vmin);
  }
  #NavMenu li:first-child {
    border: none;
  }
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.App-footer {
  background-color: #000000;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 1vmin);
  color: white;
}
.App-footer ul{
    list-style: none;
    padding: 0px;
}

#FooterUp{
   min-height: 7vh;
}
#FooterDown{
    min-height: 1vh;
    text-align: center;
    margin-top: 5vh;
    padding: 5vw;
    font-size: calc(3px + 2vmin);
}
.footerSection{
  float: left;
  width: 100%;
}
#GNavigation{
  width: 100%;
  float: none;
}
#GNavigation ul{
  width: 80vw;
  margin: auto;
  border-top: #ffffff solid 0.2vh;
}
#GNavigation li{
  width: 33%;
  float: left;
  display: block;
  height: 4vh;
  margin-top: 1vh;
  font-size: calc(3px + 3vmin);

}
#Services{
  width: 65%;
  float: left;
  display: none;
}

.footerBlock{
  width: 80vw;
  height: 10vh;
  margin: 3% auto;
  background-color: #7c7c7c60;
  text-align: left;
  
}
.footerBlock li{
  float: left;
  max-height: 100%;
  margin: 0%;
  height: 9.6vh;
  padding: 0.1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerBlock .textSection{
  display: block;
  text-align:  left;
}
.footerBlock h2{
  margin: 0%;
  margin-top:2.2vh; 
  padding: 0%;
  font-size: calc(10px + 1.2vh);
  margin-left: 7%;
}
.footerBlock p{
  height: 20%;
  margin: 0%;
  padding: 0%;
  font-size: calc(5px + 0.4vh);
  color: #515151;
  margin-left: 7%;
}
.footerBlock img{
  max-width: 90%;
  max-height: 90%;
  
}
.footerBlock ul{
  display: block;
  height:  100%;

}

.App-footer #ContactInfo li:first-child{
  background-color: #6b6b6b;
}
#GNavigation li:first-child{
  display: none;
}

.colum-1-3{
  width: 30%;
  margin: 1.5% 0%;
}
.colum-1-6{
  width: 15%;
  margin: 0.75% 0%;
}
.colum-2-3{
  width: 60%;
  margin: 3% 0%;
}
.colum-1-4{
  width: 15%;
  margin: 5% 0%;
}
.colum-3-4{
  width: 79%;
  margin: 10% 0%;
}
.footerSection a:hover{
  color: #00aeff;
}
@media only screen and (min-device-width : 768px) {
  .footerSection{
    float: left;
    width: 48vw;
    padding-top:  2vh;
    text-align: left;
  }
  #GNavigation{
    width: 25%;
    float: left;
    margin-left: 10%;
    margin-top: 3%;
  }
  #GNavigation ul{
    width: 100%;
    margin: auto;
    border-top:none;
    
  }
  #GNavigation li{
    width: 100%;
    float: none;
    display: block;
    height: auto;
    margin-top: 0.5vh;
    font-size: calc(3px + 1vmin);
    display: flex;
    text-align: left;
  }
  #GNavigation li:first-child{
    display:block;
  }
  #Services{
    width: 65%;
    float: right;
    display: block;
    margin-top: 3%;
  }
  #Services li{
    min-height: 20%;
    display: flex;
    text-align: left;
    margin-top: 0.5vh;
  }
  #Services ul{
    width: 50%;
    float: left;
    display: block;
  }
  .footerSection li:first-child{
    color: #00aeff;
  }
  
  #MailInfo li{
    color: #ffffff;
  }
  .footerSection li{
    font-size: calc(3px + 1vmin);
  }
  
  .footerBlock{
    width: 22vw;
    height: 5.7vh;
    margin: 3%;
    margin-right: 0.5vw;
    background-color: #7c7c7c60;
    text-align: left;
    float: left;
    
  }
  .footerBlock h2{
    margin: 0%;
    margin-top:0%; 
    padding: 0%;
    font-size: calc(8px + 1vmin);
    margin-left: 5%;
  }
  .footerBlock p{
    height: 20%;
    margin: 0%;
    padding: 0%;
    font-size: calc(2px + 1vmin);
    color: #515151;
    margin-left: 5%;
  }
  .footerBlock li{
    height: 5.5vh;
  }
  #FooterDown{
    min-height: 1vh;
    text-align: center;
    margin-top: 1vh;
    padding: 5vw;
    font-size: calc(3px + 1vmin);
}
}
.App-home{
    background-color: #282c342d;
    height: auto;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2.5px + 3.5vmin);
    color: white;
    min-height: 100vh;
}
.fullSize{
    height: 100vh;
    width: 100vw;
    background: center no-repeat;
    background-size: cover;
    padding: 0%;
}
.bannerSection{
    height: 90vh;
    width: 90vw;
    padding: 5vh 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Banner images*/
#Banner{
    background-image: url(/static/media/Foto_Home_1366x768.28c7a4de.png);  
    justify-content: left;
}
#Home0{
    background-image: url(/static/media/Foto_ObraCivil_1366x768.aa355ec9.png);  
}
#Home1{
    background-image: url(/static/media/Foto_PinturaAcabados_1366x768.57d9e41d.png);  
}
#Home2{
    background-image: url(/static/media/Foto_CableadoEstructurado_1366x768.446fb926.png);  
}
#Home3{
    background-image: url(/static/media/Foto_SistemaSeguridad_1366x768.5cff1553.png);  
}
#Home4{
    background-image: url(/static/media/Foto_RedesELectricas_1366x768.0d7b3817.png);  
}
#Home5{
    background-image: url(/static/media/Foto_SistemaLiviano_1366x768.c1da170b.png);  
}


#BannerInfo{
    max-width: 50vw;
    margin-top: 70vh;
}
ul{
    list-style: none;
    text-align: center;
    padding: 0%;
    margin: 0%;
}
li{
    vertical-align: middle
}
.btnObj{
    float: none;
    width: 36vw;
    height: 14vw;
    display: block;
    margin: 10%;
    margin: 5% auto ;
    /*font-size: calc(3.5px + 4vmin);*/
    font-size: 80%;
    border: solid 0.2vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex; /* Safari */
    transition:  0.5s;
    box-shadow: 0vh ;
}

.btnObj:hover{
    width: 38vw;
    height: 40vw;
    box-shadow: 0.8vh 0.8vh  #686868a8;
    border: solid 0.4vh;
}

/*Blue section*/

.blueSection h1{
    color: #00aeff;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.blueSection p{
    margin-top: 0px;
}
.yellowSection .bannerSection{
    background: #ffdb4a2f;
}
.yellowSection .infoBtn{
    background: #ffdc4a;
    Border-color: #ffdc4a;
    color: #ffffff;
}
.yellowSection .galeryBtn{
    background: none;
    Border-color: #ffdc4a;
    color: #ffdc4a;
}
.blueSection .infoBtn{
    background: #00aeff;
    color: #ffffff;
    border-color: #00aeff;
}

.blueSection .galeryBtn{
    background: none;
    Border-color: #00aeff;
    color: #00aeff;
}
.blueSection .bannerSection{
    background: #00aeff2f;
}
@media only screen and (min-device-width : 768px) {
    .btnObj{
        max-width: 180px;
        max-height: 70px;
        font-size: 60%;
    }
  }
@font-face {
    font-family: Gothic;
    src: url(/static/media/GOTHIC.cfce6abb.TTF);
}
.App-Content{
    background-color: #282c342d;
    height: auto;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2.5px + 3.5vmin);
    color: rgb(255, 255, 255);
    min-height: 100vh;
    font: Gothic;
    background-color: #ffffff;
}
.App-Content .fullSize{
    height: 100vh;
    width: 100vw;
    background: center no-repeat;
    background-size: cover;
    padding: 0%;
}
.App-Content .bannerSection{
    height: 90vh;
    width: 90vw;
    padding: 5vh 5vw;
    display: flex;
    justify-content: center;
    align-items: left;
    justify-content: left;
}
#Triangle{
    background: 100% 100% no-repeat;
    width: 100vw;
    min-height: 5vh;
    position: absolute;
    left: 0;
    bottom: 0px;
    background-position: center 0.1vh;
    background-size: 100% 100%;
    display: block;
}
.yellowSection #Triangle{
    
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAAAqCAYAAABIpxtjAAAABHNCSVQICAgIfAhkiAAABR5JREFUeJzt3c9PE2kcBvDnOwVpNMKGSGKMm7URdDX+XAVaTx5MgE30tn/l3jQRIR48MQOoqGtWdylWIzEkbEzUaKjQefZAqW3VyI/pvNPp87nTPgd48uTNy4xBJKVYGj+G0CuALADIg/YrjI8BBDD68LwZy91ecZ1TpBXMdQCRKLB0NQv2DCPM5AEWACsAOLyNH30F0AfMh1cJUPEe29BkudV5RVpN5S5t6etVjgsw6977B7MMYAEwH0AAcMaGJpf3/LkiMVO5S+LxzfX9+Lg+AniFzVWOPMwG4gvAZQDBZuGHPmAPte4l6VTukjgsjg2Cmc0SBwswOwcg4zpXTfO6tw3fBqdfu44lUk/lLk45X+XRWWk4u7fyvOXurbkOJZ1L5S6xSvwqjwq5DsMjAAHMfHihb7k7L13Hks6hcpeW4eqNg3hfHgWrN1iIPMz6XedyqHHdZ/c9sCO3PrkOJemkcpfI8MX4SYR1xyuwMwA817kSrALyyZez+4pvg1NF16EkHVTusitcutYHZIa1yiNGrqL+Zs6B7jmte9kNlbv8EAlDcew0UH9WjlOA6fen9bTuZVf0xylf4dK1PoRd+doNFsMoYH2uc0kV+RaGAEAAC3309szawM0PrmNJsqjcO5xWeSqEAJ9i6zgnsxFYbvq561Dilv6AOwxfj/VjzYa1ylNO677jqdxTjISH0tjZpodpnXCdS5z4at3j2PQ/ZqDrYNIaKvcU4euxfnxGAfS2HnE7AsNB17kkqfgOxGztZo63Edjxu+9cp5JoqNzblFa5RI8E8ezLEzErPgan/ta6b08q9zbBxYkBeOGIVrnEq2ndZzlvP0+9dZ1KfkzlnkDkHxkU359vvMFix13nEqn6t+ExCrmpv8wQug4ljVTuCcDFiYHNo5Xav+5fhtkB17lEtoX4AOMctm7m7IOvde+eyj1m5KVuFA+d1SqXlGta971Pzf6suA7VSVTuLcbS74cRhldAq5Y5LgOWdZ1LJFbkRwD3695m5dvQ5KrrWGmmco8QeakbpUMXm26w/OI6l0gikUuNN3N6H2vdR0flvgda5SIRal73Xd6c5W6vuI7VrlTu28TFiR5kwvNa5SKxetV4dv/fgtmDddeh2oHK/Tu4OHG06QbLRZj1uM4l0tm4BuA+gABGH543o3X/bSp3VFc5+NtmkddusBx1nUtEtkXr/hs6sty1ykXSrGndhzZnQ5PLrlPFLfXlztLVLDayF7XKRToYuYz61xfCHtrQZNl1rFZKXbmzNH4MoVcAqy9pJi7ArNt1LhFJELIMYOHLVUzOpG3dt3W5s3Q1C/YMN91gOew6l4i0oeZ137W2YLl7a65j7VZblbtWuYjEhlyH4RGAAGY+vNC33J2XrmNtV2LLnW+u78fa50ta5SKSICsNN3OsPJ/UdZ+YcmdxbBBseJjWOQAZ17lERL4rweveSbnzzfX9+Lg+UncVMQ+zARdZREQiRa6i/uz+QPecHbn1Ke4YsZS7VrmIdLAKyCe1mzlW8W1wqtjqL4283Ll64yDel0fB6lk5kYdZf9TfIyLStprX/U/7HtjAzQ9RfsWey50vxk8irDtegZ0B4O09mohIxwgBPsVW4Wc2AstNP9/LB+6o3LXKRURiQr6FIcDW6wt7e2Z3su6/W+4kDKXxE1rlIiKJsKN1Xyt3Ll3rQ9iVr91gMYwC1hdLZBER2bnmdY/KvB2/+w4AbPMJiXYFHk4hTM69dxER2SEPRIhnAGf+B8iMZYi455TrAAAAAElFTkSuQmCC);  

}
.blueSection #Triangle{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAAAqCAYAAABIpxtjAAAABHNCSVQICAgIfAhkiAAABL5JREFUeJzt3e1PFGcYxeHfvUAEWkJiQkIjSU3qS218owVhtrYNfzmJRWaFVURJta1NNKUpCQmJsUWJ7tz9sMO4C1p5md1ndvZc3909H+Tk5GF4xhApq9jP844IJ8KZx/gaWAdqODEJyyzYVuiYIp1goQOI5GLRhzFmqTCPEwERxuQR/uULIMaIcWp8wToXba/DaUU6TuUuvenwKr8JDJ36c509YC0r++a63zz154p0mcpdiq/uo7zmVlrkEcY8MNG173c2MWoYMQ1izvFA616KTuUuxbPsF2gQkTAPRMB1jIHQsTIH1z3E/GR/ho4l0krlLmGFXuV5cbbYP7tPqOGssmBvQseS/qVyl+4q+irPz1uch9lxziAxkT0PHUr6h8pdOmfJx3DmsiJvrvKzoWMFc3Ddf859Zmw3dCwpJ5W75GfJL5O0Ha9cBSqhYxWW0wAeATEVagwQU7VnoWNJOajc5WTqPs5rZrXKc7eNp0c5RswIK1r3chIqd/k0d+Mu36SrfB4jAq6g/z+dp3UvJ6QfTjms7uPstvylJ8xhjIeOJZmddN3vL/x73LZXoUNJsajc+51WeRkkwEZ2nDNIjao9DR1KwtIPcL9Z9rO8Y1arvPS07vucyr3M3CsscY0kW+QRcCl0LAni8LqP+BUzDx1MOkPlXibNVd76KOItYCx0LCko5yVwj/1n70epMWMvQ8eSfKjce5VWueTPgSc4MUaNCjHf84vWfW9SufeKOz4B6R0sWuXSLQfX/SCrVG0ndCz5NJV7EbkP8DM3Woo8Ar4KHUsk9RsQ4+mz97d5jFkSOpS0U7kXwR2fwImoZC+fmMH4LHQskSN6Bay0/LI21roPT+XebXUfYpdrWuVScgfX/QZmjdCh+onKvdMWfZIK1fSXnvPpKh8OHUukq5x/MerpjZjN8/sfbTt0rDJTueepucqnsbY/3f8ydCyRgvoD0rdZGTE/sK51nx+V+2lolYvk5+C6T1hhwbZCx+pVKvej+t3P8Dc3tMpFuuoF+49hOjVGWWPG3oYO1QtU7h+z6FMMZk+vRMA0xpnQsUT6mvMGow7UcGISlrXuP0zlDs1V/hffMpDejNh8kmUqdCwRORKt+w/oz3LXKhcpr8PrfoUF2wwdq9vKX+6LPowxrVUu0seczez64wYx53jARdsLHauTylfusZ/PbkZsvnziJjAUOpaIFIizB6xlRznNs/tSrfveLvfmKp+l0vIEizEZOpaI9KCD695ZY8HehI51Ur1V7lrlItI9b3EeZoU/SExkz0OHOqrilnvdR/mH77TKRaQwnC32n8xJqOGsFnXdF6fcl/0CDSKS7DKt6xgDoWOJiPyPwq77MOVe91Fepy+eeH874kSQLCIi+drO7ssxYkZYYcZ2ux2iO+WuVS4i/cppAI8gvf54gJiqPev01+Zf7ks+hjOXFXlzlZ/N/XtERHpX+7o37nPbXuX5Bacv9yW/TNJ2vHIVqJz6c0VE+kcCbLS8zapG1Z6e5gOPV+5a5SIi3bKTlv3+wr93nHX/8XJ3N+5ySatcRKQQjrXu35d73cfZbburfA5jvPN5RUTkhNrX/QirzNhLAGPRp6hQpcIVkgI99y4iIsdTwUl4QsLyf/1A6RQyT/QwAAAAAElFTkSuQmCC);  
}
/*Banner images*/
#Banner{
    background-image: url(/static/media/Foto_Home_1366x768.28c7a4de.png);  
}
#Home0{
    background-image: url(/static/media/Foto_ObraCivil_1366x768.aa355ec9.png);  
}
#Home1{
    background-image: url(/static/media/Foto_PinturaAcabados_1366x768.57d9e41d.png);  
}
#Home2{
    background-image: url(/static/media/Foto_CableadoEstructurado_1366x768.446fb926.png);  

}
#Home3{
    background-image: url(/static/media/Foto_SistemaSeguridad_1366x768.5cff1553.png);  
    
}
#Home4{
    background-image: url(/static/media/Foto_RedesELectricas_1366x768.0d7b3817.png);  
}
#Home5{
    background-image: url(/static/media/Foto_SistemaLiviano_1366x768.c1da170b.png);  
}
#Gallery{
    background-image: url(/static/media/FondoWeb1.155d29af.jpg);
}


.App-Content #BannerInfo{
    max-width: 50vw;
    margin-top: 50vh;
}
ul{
    list-style: none;
    text-align: center;
    padding: 0%;
    margin: 0%;
}
li{
    vertical-align: middle
}
a{
    display: block;
}

/*Blue section*/
.App-Content .blueSection h1{
    color: #00aeff;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.App-Content .blueSection p{
    margin-top: 0px;
}
/**/
.yellowSection h1{
    color: #ffdc4a;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.yellowSection .bannerSection{
    background: #ffdb4a2f;
}
.yellowSection .infoBtn{
    background: #ffdc4a;
    Border-color: #ffdc4a;
    color: #ffffff;
}
.yellowSection .galeryBtn{
    background: none;
    Border-color: #ffdc4a;
    color: #ffdc4a;
}
.blueSection .infoBtn{
    background: #00aeff;
    color: #ffffff;
    border-color: #00aeff;
}
.blueSection .galeryBtn{
    background: none;
    Border-color: #00aeff;
    color: #00aeff;
}
.blueSection .bannerSection{
    background: #00aeff2f;
}
.App-Content #InfoSection {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: left;
    justify-content: left;
    font-size: calc(1.5px + 2.5vmin);
}
/*#InfoSection .yellowSection li::before {
    /*content: "• ";
    color: #ffdc4a; /* or whatever color you prefer 
  }
  #InfoSection .blueSection li::before {
    /*content: "• ";
    color: #00aeff; /* or whatever color you prefer 
  }*/
#InfoSection ul{
    list-style: disc ;
   
    padding-left: 5vw;
    padding-top: 1vh;
}
#InfoSection .blueSection  {
    color: #00aeff;
}
#InfoSection .yellowSection{
    color: #ffdc4a;
}

#InfoSection li{

    margin-top: 1vh;
    margin-bottom: 1vh;
    text-align: justify;
    width: 90%;
    
}
#InfoSection p{
    color:  #5a5a5a;
}
@media only screen and (min-device-width : 768px) {
   
    #Triangle{
        display:  none;
    }
    .App-Content #InfoSection {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: left;
        justify-content: left;
        font-size: calc(1.5px + 2vmin);
    }
  }
@font-face {
  font-family: Gothic;
  src: url(/static/media/GOTHIC.cfce6abb.TTF);
}
#ImageContact {
  /* background: url("../images/Contact/IMG_0297_2.png") center no-repeat;*/
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 10vh;
}
.App-Contacto {
  height: auto;
  width: 100vw;
  background: url(/static/media/Foto_Galeria_1366x768.61edb22d.png) no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 96vh;
  width: 90vw;
  padding: 5vh 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.contactSection {
  float: left;
  height: 100%;
  width: 60vw;
  height: auto;
  max-width: 500px;
}
#InfoContact {
  width: auto;
  margin-right: 5vw;
}
#FormContact {
  width: 60%;
}

#InfoContact div {
  width: auto;
  margin: auto;
}
.App-Contacto #ContactInfo {
  height: 20%;
  background-color: #00aeff;
  text-align: left;
  padding: 5%;
}
.App-Contacto ul {
  text-align: left;
  width: 90%;
  padding: 5%;
}

.App-Contacto li {
  height: 20%;
  font-size: calc(3px + 1vmin);
}
.App-Contacto li:first-child {
  height: 60%;
  font-size: calc(3px + 2.1vh);
}
#ContactInfo ul {
  padding: 0%;
}
#InfoContact #ContactImage {
  height: auto;
  padding: 0%;
  background-color: #d1d1d1;
  text-align: center;
}
#InfoContact #ContactImage img {
  height: 30vh;
  width: 22.5vh;
  /*max-width: 100%;*/
  max-width: 475px;
  max-height: 633.33px;
}
/*Form Section*/
form {
  font: Gothic;
}
.formInputs {
  width: 90%;
  margin-top: 1vh;
  border-radius: 0.5vmin;
  padding: 1%;
  font: Gothic;
}
label {
  font-size: calc(2px + 1.7vmin);
}

textarea {
  height: 10vh;
  resize: none;
  overflow: scroll;
}
.App-Contacto button {
  background-color: #00aeff;
  color: #ffffff;
  border: none;
  height: 4vh;
  width: 20vh;
  border-radius: 0.5vmin;
  margin-top: 4vh;
}
@media only screen and (min-device-width: 0px) and (max-device-width: 768px) {
  .contactSection {
    float: none;
    height: 100%;
    width: 100vw;
    height: auto;
  }
  #InfoContact {
    width: 51vw;
    margin: auto;
  }
  #FormContact {
    width: 100%;
  }

  .App-Contacto #ContactInfo {
    height: 40%;
    text-align: left;
    padding: 5%;
  }
  .App-Contacto ul {
    text-align: left;
    width: 90%;
    padding: 1%;
  }
  .App-Contacto li {
    height: 20%;
    font-size: calc(3px + 4vmin);
  }
  .App-Contacto li:first-child {
    height: 60%;
    font-size: calc(3px + 5.2vmin);
  }
  #ContactSection {
    margin-top: 5vh;
  }
  #InfoContact #ContactImage img {
    width: 50vw;
    height: 66.66vw;
    max-width: 166px;
    max-height: 221px;
  }
  /*Form Section*/
  form {
    font: "Gothic";
    margin-top: 3vh;
  }
  .formInputs {
    width: 90%;
    margin-top: 1vh;
    border-radius: 0.5vmin;
    padding: 1%;
    font: Gothic;
  }
  label {
    font-size: calc(3px + 2.4vmin);
    margin-top: 3vh !important;
  }

  textarea {
    height: 10vh;
    resize: none;
    overflow: scroll;
  }
  .App-Contacto button {
    background-color: #00aeff;
    color: #ffffff;
    border: none;
    height: 4vh;
    width: 20vh;
    border-radius: 0.5vmin;
    margin-top: 4vh;
  }
}

