@font-face {
    font-family: Gothic;
    src: url("../fonts/GOTHIC.TTF");
}
.App-Content{
    background-color: #282c342d;
    height: auto;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(2.5px + 3.5vmin);
    color: rgb(255, 255, 255);
    min-height: 100vh;
    font: Gothic;
    background-color: #ffffff;
}
.App-Content .fullSize{
    height: 100vh;
    width: 100vw;
    background: center no-repeat;
    background-size: cover;
    padding: 0%;
}
.App-Content .bannerSection{
    height: 90vh;
    width: 90vw;
    padding: 5vh 5vw;
    display: flex;
    justify-content: center;
    align-items: left;
    justify-content: left;
}
#Triangle{
    background: 100% 100% no-repeat;
    width: 100vw;
    min-height: 5vh;
    position: absolute;
    left: 0;
    bottom: 0px;
    background-position: center 0.1vh;
    background-size: 100% 100%;
    display: block;
}
.yellowSection #Triangle{
    
    background-image: url("../images/Icons/Path-1.png") ;  

}
.blueSection #Triangle{
    background-image: url("../images/Icons/Path-2.png") ;  
}
/*Banner images*/
#Banner{
    background-image: url("../images/BackGround/Foto_Home_1366x768.png") ;  
}
#Home0{
    background-image: url("../images/BackGround/Foto_ObraCivil_1366x768.png") ;  
}
#Home1{
    background-image: url("../images/BackGround/Foto_PinturaAcabados_1366x768.png") ;  
}
#Home2{
    background-image: url("../images/BackGround/Foto_CableadoEstructurado_1366x768.png") ;  

}
#Home3{
    background-image: url("../images/BackGround/Foto_SistemaSeguridad_1366x768.png") ;  
    
}
#Home4{
    background-image: url("../images/BackGround/Foto_RedesELectricas_1366x768.png") ;  
}
#Home5{
    background-image: url("../images/BackGround/Foto_SistemaLiviano_1366x768.png") ;  
}
#Gallery{
    background-image: url("../images/BackGround/FondoWeb1.jpg") ;
}


.App-Content #BannerInfo{
    max-width: 50vw;
    margin-top: 50vh;
}
ul{
    list-style: none;
    text-align: center;
    padding: 0%;
    margin: 0%;
}
li{
    vertical-align: middle
}
a{
    display: block;
}

/*Blue section*/
.App-Content .blueSection h1{
    color: #00aeff;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.App-Content .blueSection p{
    margin-top: 0px;
}
/**/
.yellowSection h1{
    color: #ffdc4a;
    font-size: calc(2.5px + 5vmin);
    margin-bottom: 0px;
}
.yellowSection .bannerSection{
    background: #ffdb4a2f;
}
.yellowSection .infoBtn{
    background: #ffdc4a;
    Border-color: #ffdc4a;
    color: #ffffff;
}
.yellowSection .galeryBtn{
    background: none;
    Border-color: #ffdc4a;
    color: #ffdc4a;
}
.blueSection .infoBtn{
    background: #00aeff;
    color: #ffffff;
    border-color: #00aeff;
}
.blueSection .galeryBtn{
    background: none;
    Border-color: #00aeff;
    color: #00aeff;
}
.blueSection .bannerSection{
    background: #00aeff2f;
}
.App-Content #InfoSection {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: left;
    justify-content: left;
    font-size: calc(1.5px + 2.5vmin);
}
/*#InfoSection .yellowSection li::before {
    /*content: "• ";
    color: #ffdc4a; /* or whatever color you prefer 
  }
  #InfoSection .blueSection li::before {
    /*content: "• ";
    color: #00aeff; /* or whatever color you prefer 
  }*/
#InfoSection ul{
    list-style: disc ;
   
    padding-left: 5vw;
    padding-top: 1vh;
}
#InfoSection .blueSection  {
    color: #00aeff;
}
#InfoSection .yellowSection{
    color: #ffdc4a;
}

#InfoSection li{

    margin-top: 1vh;
    margin-bottom: 1vh;
    text-align: justify;
    width: 90%;
    
}
#InfoSection p{
    color:  #5a5a5a;
}
@media only screen and (min-device-width : 768px) {
   
    #Triangle{
        display:  none;
    }
    .App-Content #InfoSection {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: left;
        justify-content: left;
        font-size: calc(1.5px + 2vmin);
    }
  }