@font-face {
  font-family: Gothic;
  src: url("../fonts/GOTHIC.TTF");
}
#ImageContact {
  /* background: url("../images/Contact/IMG_0297_2.png") center no-repeat;*/
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 10vh;
}
.App-Contacto {
  height: auto;
  width: 100vw;
  background: url("../images/BackGround/Foto_Galeria_1366x768.png") no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 96vh;
  width: 90vw;
  padding: 5vh 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.contactSection {
  float: left;
  height: 100%;
  width: 60vw;
  height: auto;
  max-width: 500px;
}
#InfoContact {
  width: auto;
  margin-right: 5vw;
}
#FormContact {
  width: 60%;
}

#InfoContact div {
  width: auto;
  margin: auto;
}
.App-Contacto #ContactInfo {
  height: 20%;
  background-color: #00aeff;
  text-align: left;
  padding: 5%;
}
.App-Contacto ul {
  text-align: left;
  width: 90%;
  padding: 5%;
}

.App-Contacto li {
  height: 20%;
  font-size: calc(3px + 1vmin);
}
.App-Contacto li:first-child {
  height: 60%;
  font-size: calc(3px + 2.1vh);
}
#ContactInfo ul {
  padding: 0%;
}
#InfoContact #ContactImage {
  height: auto;
  padding: 0%;
  background-color: #d1d1d1;
  text-align: center;
}
#InfoContact #ContactImage img {
  height: 30vh;
  width: 22.5vh;
  /*max-width: 100%;*/
  max-width: 475px;
  max-height: 633.33px;
}
/*Form Section*/
form {
  font: Gothic;
}
.formInputs {
  width: 90%;
  margin-top: 1vh;
  border-radius: 0.5vmin;
  padding: 1%;
  font: Gothic;
}
label {
  font-size: calc(2px + 1.7vmin);
}

textarea {
  height: 10vh;
  resize: none;
  overflow: scroll;
}
.App-Contacto button {
  background-color: #00aeff;
  color: #ffffff;
  border: none;
  height: 4vh;
  width: 20vh;
  border-radius: 0.5vmin;
  margin-top: 4vh;
}
@media only screen and (min-device-width: 0px) and (max-device-width: 768px) {
  .contactSection {
    float: none;
    height: 100%;
    width: 100vw;
    height: auto;
  }
  #InfoContact {
    width: 51vw;
    margin: auto;
  }
  #FormContact {
    width: 100%;
  }

  .App-Contacto #ContactInfo {
    height: 40%;
    text-align: left;
    padding: 5%;
  }
  .App-Contacto ul {
    text-align: left;
    width: 90%;
    padding: 1%;
  }
  .App-Contacto li {
    height: 20%;
    font-size: calc(3px + 4vmin);
  }
  .App-Contacto li:first-child {
    height: 60%;
    font-size: calc(3px + 5.2vmin);
  }
  #ContactSection {
    margin-top: 5vh;
  }
  #InfoContact #ContactImage img {
    width: 50vw;
    height: 66.66vw;
    max-width: 166px;
    max-height: 221px;
  }
  /*Form Section*/
  form {
    font: "Gothic";
    margin-top: 3vh;
  }
  .formInputs {
    width: 90%;
    margin-top: 1vh;
    border-radius: 0.5vmin;
    padding: 1%;
    font: Gothic;
  }
  label {
    font-size: calc(3px + 2.4vmin);
    margin-top: 3vh !important;
  }

  textarea {
    height: 10vh;
    resize: none;
    overflow: scroll;
  }
  .App-Contacto button {
    background-color: #00aeff;
    color: #ffffff;
    border: none;
    height: 4vh;
    width: 20vh;
    border-radius: 0.5vmin;
    margin-top: 4vh;
  }
}
