.App-footer {
  background-color: #000000;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 1vmin);
  color: white;
}
.App-footer ul{
    list-style: none;
    padding: 0px;
}

#FooterUp{
   min-height: 7vh;
}
#FooterDown{
    min-height: 1vh;
    text-align: center;
    margin-top: 5vh;
    padding: 5vw;
    font-size: calc(3px + 2vmin);
}
.footerSection{
  float: left;
  width: 100%;
}
#GNavigation{
  width: 100%;
  float: none;
}
#GNavigation ul{
  width: 80vw;
  margin: auto;
  border-top: #ffffff solid 0.2vh;
}
#GNavigation li{
  width: 33%;
  float: left;
  display: block;
  height: 4vh;
  margin-top: 1vh;
  font-size: calc(3px + 3vmin);

}
#Services{
  width: 65%;
  float: left;
  display: none;
}

.footerBlock{
  width: 80vw;
  height: 10vh;
  margin: 3% auto;
  background-color: #7c7c7c60;
  text-align: left;
  
}
.footerBlock li{
  float: left;
  max-height: 100%;
  margin: 0%;
  height: 9.6vh;
  padding: 0.1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerBlock .textSection{
  display: block;
  text-align:  left;
}
.footerBlock h2{
  margin: 0%;
  margin-top:2.2vh; 
  padding: 0%;
  font-size: calc(10px + 1.2vh);
  margin-left: 7%;
}
.footerBlock p{
  height: 20%;
  margin: 0%;
  padding: 0%;
  font-size: calc(5px + 0.4vh);
  color: #515151;
  margin-left: 7%;
}
.footerBlock img{
  max-width: 90%;
  max-height: 90%;
  
}
.footerBlock ul{
  display: block;
  height:  100%;

}

.App-footer #ContactInfo li:first-child{
  background-color: #6b6b6b;
}
#GNavigation li:first-child{
  display: none;
}

.colum-1-3{
  width: 30%;
  margin: 1.5% 0%;
}
.colum-1-6{
  width: 15%;
  margin: 0.75% 0%;
}
.colum-2-3{
  width: 60%;
  margin: 3% 0%;
}
.colum-1-4{
  width: 15%;
  margin: 5% 0%;
}
.colum-3-4{
  width: 79%;
  margin: 10% 0%;
}
.footerSection a:hover{
  color: #00aeff;
}
@media only screen and (min-device-width : 768px) {
  .footerSection{
    float: left;
    width: 48vw;
    padding-top:  2vh;
    text-align: left;
  }
  #GNavigation{
    width: 25%;
    float: left;
    margin-left: 10%;
    margin-top: 3%;
  }
  #GNavigation ul{
    width: 100%;
    margin: auto;
    border-top:none;
    
  }
  #GNavigation li{
    width: 100%;
    float: none;
    display: block;
    height: auto;
    margin-top: 0.5vh;
    font-size: calc(3px + 1vmin);
    display: flex;
    text-align: left;
  }
  #GNavigation li:first-child{
    display:block;
  }
  #Services{
    width: 65%;
    float: right;
    display: block;
    margin-top: 3%;
  }
  #Services li{
    min-height: 20%;
    display: flex;
    text-align: left;
    margin-top: 0.5vh;
  }
  #Services ul{
    width: 50%;
    float: left;
    display: block;
  }
  .footerSection li:first-child{
    color: #00aeff;
  }
  
  #MailInfo li{
    color: #ffffff;
  }
  .footerSection li{
    font-size: calc(3px + 1vmin);
  }
  
  .footerBlock{
    width: 22vw;
    height: 5.7vh;
    margin: 3%;
    margin-right: 0.5vw;
    background-color: #7c7c7c60;
    text-align: left;
    float: left;
    
  }
  .footerBlock h2{
    margin: 0%;
    margin-top:0%; 
    padding: 0%;
    font-size: calc(8px + 1vmin);
    margin-left: 5%;
  }
  .footerBlock p{
    height: 20%;
    margin: 0%;
    padding: 0%;
    font-size: calc(2px + 1vmin);
    color: #515151;
    margin-left: 5%;
  }
  .footerBlock li{
    height: 5.5vh;
  }
  #FooterDown{
    min-height: 1vh;
    text-align: center;
    margin-top: 1vh;
    padding: 5vw;
    font-size: calc(3px + 1vmin);
}
}