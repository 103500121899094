@font-face {
    font-family: Gothic_Bold;
    src: url("../fonts/GOTHICB.TTF");
}
@font-face {
    font-family: Gothic;
    src: url("../fonts/GOTHIC.TTF");
}
body, html{
    width: 100%;
    font-family: Gothic_Bold;
}
a, a:link:active, a:visited:active { 
    text-decoration:none; 
    color: (internal value);
}
